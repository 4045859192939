import request from '@/utils/request'

//供给信息--提交需求--分页查询
export function getSupplyList(data) {
    return request({
        url: '/supply/query',
        method: 'post',
        data
    })
}

//供给信息--提交需求--更新供给
export function updatedSupply(data) {
    return request({
        url: '/supply/update',
        method: 'post',
        data
    })
}

//供给信息--需求--分页查询
export function getDemand(data) {
    return request({
        url: '/demand/query',
        method: 'post',
        data
    })
}

//供给信息--报备供给--更新需求
export function updatedDemand(data) {
    return request({
        url: '/demand/update',
        method: 'post',
        data
    })
}

//供给信息--提交需求--id查询
export function getSupplyDetail(data) {
    return request({
        url: `/supply/queryById`,
        method: 'get',
        params: data
    })
}

//供给信息--报备设备--id查询
export function getDemandDetail(data) {
    return request({
        url: `/demand/queryById`,
        method: 'get',
        params: data
    })
}

//供需信息--分页查询需求导出
export function queryExcel(data) {
    return request({
        url: `/demand/queryExcel`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

